import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { navigate } from 'gatsby';
import loadable from '@loadable/component';

import { useTranslation } from "react-i18next"
import { useLocalization } from "gatsby-theme-i18n"

import searchType from "../../search_config/search_type.json";
import minPrice from "../../search_config/min_price.json";
import maxPrice from "../../search_config/max_price.json";
import BedroomList from "../../search_config/bedrooms.json";
import propertyTypes from "../../search_config/property_type.json";

import searchTypeAR from "../../search_config/ar/search_type.json";
import minPriceAR from "../../search_config/ar/min_price.json";
import maxPriceAR from "../../search_config/ar/max_price.json";
import BedroomListAR from "../../search_config/ar/bedrooms.json";
import propertyTypesAR from "../../search_config/ar/property_type.json";

import "./assets/styles/_index.scss"
import { PageLinks } from "../../common/site/page-static-links";
import useAreaMultiSelect from '../SearchResults/PredictiveSearch/useAreaMutiSelect';
import { OFF_PLAN } from '../../common/site/config';
const Select = loadable(() => import("react-select"));
const MultiSelectInputDubai = loadable(() => import("../MultiSelectInput/MultiSelectInput"))

const BannerSearch = (props) => {

    const { t } = useTranslation()
    const { locale } = useLocalization()

    const [priceRange, setPriceRange] = useState(false);
    const [minPriceVal, setMinPriceVal] = useState('');
    const [maxPriceVal, setMaxPriceVal] = useState('');
    const [selectedMinPriceValue, setSelectedMinPriceValue] = useState('');
    const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState('');
    const [searchTypeVal, setsearchTypeVal] = useState('buy');
    const [propertyval, setPropertyVal] = useState('');
    const [bedroomsVal, setBedroomsVal] = useState('')
    const [areaValue, setAreaValue] = useState('');
    const { selectedAreas, handleRemoveAllAreas } = useAreaMultiSelect({})
    const [first, ...rest] = selectedAreas
    // Min price
    const onChangeMinPrice = (e) => {
        setSelectedMinPriceValue(e.value);
    }
    // Min price

    // Max Price
    const onChangeMaxPrice = (e) => {
        setSelectedMaxPriceValue(e.value);
    }
    // Max Price 

    const isOffplan = props.offplanpage === true ? true : false
    // Dropdown react select styles
    const customStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                // backgroundColor: isSelected ? "#081D3C" : "null",
                // color: isSelected ? "#ffffff" : "#34373D",
                "&:hover": {
                    // color: "#ffffff",
                    cursor: "pointer",
                    // backgroundColor: "#081D3C",
                }
            }
        },
        control: styles => ({
            ...styles,
            backgroundColor: null,
            border: 0,
            paddingLeft: 0,
            outline: 0,
            boxShadow: "none",
            color: "#fff",
            fontSize: "1rem",
        }),
        valueContainer: styles => ({
            ...styles,
            fontSize: "1rem",
            paddingLeft: 0,
            lineHeight: "21px",
            cursor: "pointer",
        }),
        dropdownIndicator: styles => ({
            ...styles,
            color: "#fff",
        }),
        indicatorsContainer: styles => ({
            ...styles,
            color: "#fff",
            cursor: "pointer",
        }),
        indicatorSeparator: () => null,
        placeholder: defaultStyles => {
            return {
                ...defaultStyles,
                color: "#ffffff",
                marginLeft: 0,
            }
        },
    }
    // Dropdown react select styles
    //filters values
    //const search_type_options = searchType;

    // Price range
    const priceRangeBtn = () => setPriceRange(price => !price);
    // Price range
    useEffect(() => {
        if (selectedAreas && selectedAreas.length > 0) {
            handleRemoveAllAreas(selectedAreas)
        }
    }, [])
    const handleSubmit = event => {
        event.preventDefault();
        let searchFilterResults = "";
        let areas = []
        if (selectedAreas.length > 0) {
            areas = selectedAreas.length > 0 && selectedAreas.map((item) => {
                return item.slug.replace(/-ar/g, "")
            })
            areas = areas?.join(',')?.replaceAll(/,/g, "-and-")
        }
        if (areas && areas.length > 0) {
            searchFilterResults += searchTypeVal === "buy" ?
                `/properties/for-sale/in-` + areas.replaceAll(/ /g, "-").toLowerCase() + '/'
                : `/properties/for-rent/in-` + areas.replaceAll(/ /g, "-").toLowerCase() + '/'
        }
        else {
            searchFilterResults += searchTypeVal === "buy" ?
                `/${PageLinks.results_sales}/`
                : `/${PageLinks.results_lettings}/`
        }
        if (bedroomsVal) {
            searchFilterResults += bedroomsVal + "-and-more-bedrooms" + "/"
        }
        if (selectedMinPriceValue && selectedMaxPriceValue) {
            searchFilterResults += "between-" + selectedMinPriceValue + "-and-" + selectedMaxPriceValue + "/"
        } else if (selectedMinPriceValue) {
            searchFilterResults += "above-" + selectedMinPriceValue + "/"
        } else if (selectedMaxPriceValue) {
            searchFilterResults += "below-" + selectedMaxPriceValue + "/"
        }
        if (propertyval) {
            searchFilterResults += "type-" + propertyval + "/";
        }
        let localpath = ''
        if(locale != "en"){
            localpath = '/'+locale;
        }
        navigate(localpath+searchFilterResults);
    }

        //filters values
        let search_type_options = searchType;
        let building_options = propertyTypes;
        let min_price_options = minPrice;
        let max_price_options = maxPrice;
        let bedroom_options = BedroomList;
        if(locale == "ar"){
            search_type_options = searchTypeAR;
            building_options = propertyTypesAR;
            min_price_options = minPriceAR;
            max_price_options = maxPriceAR;
            bedroom_options = BedroomListAR;
        }
    //props?.page_url_data?.search_type
    let search_type_key = 0

    // if (props?.page_url_data?.searchtypeVal === "lettings") {
    //     search_type_key = 1;
    //     min_price_options = minPrice["lettings"];
    //     max_price_options = maxPrice["lettings"];
    //     if(locale == "ar"){
    //         min_price_options = minPriceAR["lettings"];
    //         max_price_options = maxPriceAR["lettings"];
    //     }
    // } else if (props?.page_url_data?.searchtypeVal === "sales") {
    //     search_type_key = 0;
    // }
    //props?.page_url_data?.minpriceVal
    //let min_price_key = Object.keys(min_price_options).find(key => min_price_options[key].value === props?.page_url_data?.minpriceVal);

    //props?.page_url_data?.maxpriceVal
    //let max_price_key = Object.keys(max_price_options).find(key => max_price_options[key].value === props?.page_url_data?.maxpriceVal);

    //props?.page_url_data?.bedVal
    //let bedroom_key = Object.keys(bedroom_options).find(key => bedroom_options[key].value === props?.page_url_data?.bedVal);
    
    //props?.page_url_data?.buildingval
    //let building_key = Object.keys(building_options).find(key => building_options[key].value === props?.page_url_data?.buildingval);

    let area_val;
    if (props?.page_url_data?.areaVal && props?.page_url_data?.areaVal !== (process.env.GATSBY_DEFAULT_AREA.toLowerCase())) {
        area_val = props?.page_url_data?.areaVal
    }

    return (
        <Row>
            <Col xl={10}>
                <div className='banner-search-wrapper'>
                    <Form className="refine-form" method="post" onSubmit={handleSubmit} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
                        <input type="hidden" name="department" value={isOffplan ? OFF_PLAN : ""} />
                        <div className='banner-search-inner'>
                            <div className={`dropdown-select buy-dropdown`}>
                            <Select
                                    options={search_type_options}
                                    defaultValue={search_type_options[search_type_key]}
                                    name={"search_type"}
                                    placeholder={locale === 'en' ? "Buy" : "للبيع"}
                                    className={"select-control"}
                                    classNamePrefix={"react-select"}
                                    styles={customStyles}
                                    onChange={e => setsearchTypeVal(e.value)}
                                    isSearchable={false}
                                    components={{ DropdownIndicator: () => <i className={`icon icon-select-dropdown-dark`}></i>, IndicatorSeparator: () => null }}
                                />
                            </div>
                            <div className={`banner-search-box`}>
                                <MultiSelectInputDubai areaVal={process.env.GATSBY_DEFAULT_AREA} />
                            </div>
                            <div className={`price-range-wrapper`}>
                                <button type="button" className={`d-flex align-items-center justify-content-between ${priceRange === true ? "active" : ""}`} onClick={priceRangeBtn}>{t("price")}<i className="icon icon-select-dropdown-dark"></i></button>
                                {
                                    priceRange === true &&
                                    <div className="price-range">
                                        <div className="dropdown-select price-dropdown">
                                            <Select
                                                options={searchTypeVal === "buy" ? min_price_options['sales'] : min_price_options['lettings']}
                                                name={"search_type"}
                                                defaultValue={searchTypeVal === "buy" ? min_price_options['sales'][search_type_key] : min_price_options['lettings'][search_type_key]}
                                                placeholder={"Min Price"}
                                                className={"select-control"}
                                                classNamePrefix={"react-select"}
                                                styles={customStyles}
                                                onChange={e => { onChangeMinPrice(e); setMinPriceVal(e.value) }}
                                                isSearchable={false}
                                                components={{ DropdownIndicator: () => <i className="icon icon-select-dropdown-dark"></i>, IndicatorSeparator: () => null }}
                                            />
                                        </div>
                                        <div className="dropdown-select price-dropdown">
                                            <Select
                                                options={searchTypeVal === "buy" ? max_price_options['sales'] : max_price_options['lettings']}
                                                name={"search_type"}
                                                defaultValue={searchTypeVal === "buy" ? min_price_options['sales'][search_type_key] : min_price_options['lettings'][search_type_key]}
                                                placeholder={"Max Price"}
                                                className={"select-control"}
                                                classNamePrefix={"react-select"}
                                                styles={customStyles}
                                                onChange={e => { onChangeMaxPrice(e); setMaxPriceVal(e.value) }}
                                                isSearchable={false}
                                                components={{ DropdownIndicator: () => <i className="icon icon-select-dropdown-dark"></i>, IndicatorSeparator: () => null }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            <span className='divider'></span>
                            <div className={`dropdown-select bed-dropdown`}>
                                <Select
                                    options={bedroom_options}
                                    defaultValue={bedroom_options[search_type_key]}
                                    placeholder={"Beds"}
                                    name={"bedrooms"}
                                    className={"select-control"}
                                    classNamePrefix={"react-select"}
                                    styles={customStyles}
                                    isSearchable={false}
                                    onChange={e => setBedroomsVal(e.value)}
                                    components={{ DropdownIndicator: () => <i className={`icon icon-select-dropdown-dark`}></i>, IndicatorSeparator: () => null }}
                                />
                            </div>
                            <span className='divider'></span>
                            <div className={`dropdown-select property-dropdown`}>
                                <Select
                                    options={building_options}
                                    defaultValue={building_options[search_type_key]}
                                    name={"building_type"}
                                    placeholder={"All Types"}
                                    className={"select-control"}
                                    classNamePrefix={"react-select"}
                                    styles={customStyles}
                                    isSearchable={false}
                                    onChange={e => setPropertyVal(e.value)}
                                    components={{ DropdownIndicator: () => <i className={`icon icon-select-dropdown-dark`}></i>, IndicatorSeparator: () => null }}
                                />
                            </div>
                            <div className='btn-wrapper'>
                                <button name="submit" className="button button-primary">{t("search")}</button>
                            </div>
                        </div>

                    </Form>
                </div>
            </Col>
        </Row>
    )
}

export default BannerSearch